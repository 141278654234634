<template>
  <vp-base-layout page-title="내역 상세">
    <div class="l-wrapper">
      <div
        class="l-contents"
        style="margin: 50px 20px 50px 20px;">
        <ion-item style="margin-top: 50px">
          <ion-label>● 신청자 : </ion-label>
          <ion-input
            v-model="data.order_nm" />
        </ion-item>
        <ion-item>
          <ion-label>● 전화번호 : </ion-label>
          <ion-input
            v-model="data.order_tel_no" />
        </ion-item>
        <ion-item>
          <ion-label>● 은행 : </ion-label>
          <ion-input
            v-model="data.bank_nm" />
        </ion-item>
        <ion-item>
          <ion-label>● 예금주 : </ion-label>
          <ion-input
            v-model="data.acc_nm" />
        </ion-item>
        <ion-item>
          <ion-label>● 계좌번호 : </ion-label>
          <ion-input
            v-model="data.acc_num" />
        </ion-item>
        <ion-item>
          <ion-label>● 상태 : </ion-label>
          <ion-input
            v-model="data.acc_state_nm" />
        </ion-item>
        <ion-item>
          <ion-label>● 비고(모델) : </ion-label>
          <ion-input
            v-model="data.description" />
        </ion-item>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <p>
              접수 취소 필요시 딜러에게 요청하세요.
            </p>
          </ion-label>
        </ion-item>
      </div>
    </div>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import {
  IonLabel,
  IonInput,
  IonItem
} from '@ionic/vue'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { decrypt } from '@/utils/jsencrypt'
import VpPageTitle from '@/components//original/layouts/VpPageTitle.vue'
import { getPurchase } from '@/api/mintit/agency'

const route = useRoute()
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const data = computed(() => route.query)

const purchase = async () => {
  const params = {
    pageNum: 1,
    pageSize: 9999
  }
  const response = await getPurchase(route.params.id)
  const { results, count } = response.data
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  data.value.acc_num = decrypt(route.query.acc_num)
})
</script>

<style scoped>
ion-select{
  max-width: 80% !important;
}
.fixedLabel {
  min-width: 30% !important;
  max-width: 30% !important;
}
</style>